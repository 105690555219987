//color de fuentes
$corporativo: #04b5fc;
$corporativoAntiguo: #1cacd4;
$segundo: #282454;
$font-light: #fff;
$font-grey: #a2a2a2;
$font-dark: #000;
//color pantallas
$background-light: #fff;
$background-grey: #e8e8e8;
$background-dark: #1f1f1f;
//color bordes;
$border-grey: #dbdbdb;
$border-dark: #000;
//color acciones
$action: #0095f6;
$danger: #ed4956;
$success: #84b84c;
$validar:#ff8f00;
//Tipos de letras
$tipografiaCOntrol: 'Asap',
sans-serif;
$gotica: 'Poppins',
sans-serif;
//Responsive down size
//tamaños
$pc:1200px;
$tablet: 800px;
$tablets:550px;
$movil:400px;
$movils:350px;