@import "../index.scss";
.Tabs__container {
  padding-top: 80px;
  width: 100%;
  height: 100vh;
  .tabs_head {
    position: fixed;
    display: flex;
    align-items: stretch;
    width: 100%;
    /* border-color: #f2f2f2;
            border-bottom: 2px solid #f2f2f2; */
    background: #dbdbdd;
    overflow-x: auto;

    .tabs__toogle {
      cursor: pointer;
      display: flex;
      padding: 5px;
      height: 30px;
      align-items: center;
      justify-content: center;
      background: #f2f2f2;
      color: gray;
      border-left: 1px solid #f2f2f2;
      border-right: 1px solid #f2f2f2;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-left: 2px;
      margin-top: 1px;
      transition: all 0.3s ease-in;
      svg {
        fill: gray;
        transition: all 0.3s ease-in;
      }
      &:hover {
        color: $segundo;
        span {
          font-weight: bold;
        }
        svg {
          fill: $segundo;
        }
      }
    }
    .tabs__toogle.is-active {
      cursor: pointer;
      background: #ffffff;
      color: $segundo;
      span {
        font-weight: bold;
      }
      svg {
        fill: $segundo;
      }
    }
    //Modo Obscuro
    .tabs__toogle__dark {
      cursor: pointer;
      display: flex;
      padding: 5px;
      height: 30px;
      align-items: center;
      justify-content: center;
      background: #222222;
      color: gray;
      border-left: 1px solid #444444;
      border-right: 1px solid #444444;
      border-top: 1px solid#444444;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      margin-left: 2px;
      margin-top: 1px;
      transition: all 0.3s ease-in;
      svg {
        fill: gray;
        transition: all 0.3s ease-in;
      }
      &:hover {
        color: $corporativo;
        span {
          font-weight: bold;
        }
        svg {
          fill: $corporativo;
        }
      }
    }
    .tabs__toogle.is-active__dark {
      cursor: pointer;
      background: #111111;
      color: $corporativo;
      span {
        font-weight: bold;
      }
      svg {
        fill: $corporativo;
      }
    }
    .tab__name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: space-between !important;
      padding-left: 2px;
      padding-right: 2px;
      svg {
        margin-left: 10px;
        width: 10px;
        height: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
      span {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
        font-family: $gotica;
        font-size: 14px;
        height: 100%;
      }
    }
  }
  .tabs__body {
    height: 100%;
    padding-top: 30px;
    .tabs__content {
      display: none;
      overflow-y: auto !important;
    }
    .tabs__content.is_active {
      display: block;
      overflow-y: auto;
    }
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        color: gray;
        svg {
          fill: gray;
        }
      }
      .active {
        color: $segundo;
        span {
          font-weight: bold;
        }
        svg {
          fill: $segundo;
        }
      }
    }
  }
}

.react-tabs-container {
  border: none !important;
  margin-top: 80px !important;
  width: calc(100% - 200px) !important;
  overflow-x: auto !important;
  white-space: nowrap !important;
  background: #dbdbdd !important;
  position: fixed !important;
  z-index: 100 !important;

  .react-tabs-tab {
    max-width: 135px !important;
    min-width: 135px !important;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: #f2f2f2;
    color: gray;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    &:hover {
      .react-tabs-tab-close {
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  .react-tabs-active {
    cursor: pointer;
    background: #ffffff;
    color: $segundo;
    font-weight: bold;
  }
}

.tab-content {
  padding-top: 120px;
}

.react-tabs-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
  // background-color: #F5F5F5;
}

.react-tabs-container::-webkit-scrollbar{
  height: 7px !important;
  // background-color: #F5F5F5;
}

.react-tabs-container::-webkit-scrollbar-thumb{
  background-color: darkgray;
  outline: 1px solid slategrey;
}

.react-tabs-container-reduced {
  width: calc(100% - 80px) !important;
}
