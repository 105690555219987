@import '../index.scss';
.container_admin {
    margin-left: 80px;
    transition: 0.3 ease-in !important;
}

.container_admin_active {
    margin-left: 200px;
    transition: 0.3 ease-in !important;
}

/* .container_admin_dark {
    margin-left: 80px;
    transition: 0.3 ease-in !important;
    background-color: #111111 !important;
}

.container_admin_active_dark {
    margin-left: 200px;
    transition: 0.3 ease-in !important;
    background-color: #111111 !important;
} */

@media screen and ( max-width: $tablets) {
    .container_admin_active {
        margin-left: 0px;
    }
}