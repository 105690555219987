@import "../index.scss";

.ant-modal {

    margin: auto !important;
    width: 70% !important;
    margin: 0;
    padding: 0;
    display: flex;
    font-family: $tipografiaCOntrol;

    .ant-modal-content {
        padding: 0 !important;
        margin: 0 !important;
        .ant-modal-close {
            color: white !important;
        }
    }

    .ant-modal-header {
        padding: 15px 30px !important;
        background-color: $corporativo;
        .ant-modal-title {
            color: white;
            font-size: 19px;
        }
    }

    .ant-modal-body {
        padding: 10px 20px 40px 20px !important;
        background-color: white;
        border-radius: 100px;
        .ant-form-item {
            margin-bottom: 0 !important;
        }
    }

}

@media screen and ( max-width: 768px) {
    .ant-modal {
        width: 90% !important;
    }
}