@import '../index.scss';

.sidebar-geo {

    position: fixed;
    height: 100%;
    width: 80px;
    left: -80px;
    top: 0;
    padding: 80px 0 20px 0;
    color: white;
    background-color: $segundo;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    overflow-y: auto;

    .empresa {
        transition: 0.3s ease-in-out;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column;
        .logo {
            img {
                border: 2px solid $corporativo;
                border-color: white;
                width: 50px;
                height: 50px;
            }
        }
        .nombre {
            display: none;
        }
    }
    .graficas {
        cursor: pointer;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column;
        background-color: $corporativo;
        padding: 5px;
        border-radius: 5px;
        margin-right: 10px;
        margin-left: 10px;
        svg {
            width: 25px;
            fill: white;
            margin-right: 10px;
        }
        p {
            font-family: $gotica;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 10px;
        }
    }
    .opciones {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 10px;
            fill: white;
        }
        .arrow {
            display: none;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            flex-direction: column;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 9px;
            }
            svg {
                width: 25px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .reportes {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 12px;
            fill: white;
        }
        .arrow {
            display: none;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            flex-direction: column;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 9px;
            }
            svg {
                width: 15px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .incidencias {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 10px;
            fill: white;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            flex-direction: column;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 9px;
            }
            svg {
                width: 18px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .sub-menu {
        margin-top: -50px;
        display: none;
        position: fixed;
        z-index: 100;
        background-color: $segundo;
        box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.5);
        font-family: $tipografiaCOntrol;
        left: 70px;
        transition: 0.5s;
        padding: 2px;
        li {
            /* font-size: 13px; */
            list-style: none;
            justify-content: space-between;
            display: flex;
            position: static;
            transition: 0.5s;
            padding-left: 2px;
            padding-right: 2px;
            a {
                text-decoration: none;
                color: white;
            }
            .nominas {
                padding: 5px;
                display: none;
                position: fixed;
                z-index: 100;
                background-color: $segundo;
                box-shadow: 0px 0px 10px 1px #000;
                font-family: $tipografiaCOntrol;
                left: 180px;
                transition: 0.5s;
                li {
                    padding-bottom: 2px;
                    display: list-item;
                    position: static;
                    transition: 0.5s;
                    a {
                        padding: 10px;
                    }
                }
            }
            a {
                padding: 5px;
            }
            svg {
                margin-left: 5px;
                width: 7px;
            }
        }
        li {
            &:hover,
            &:active {
                background-color: $corporativo;
                .nominas {
                    display: block !important;
                    transition: 0.5s;
                }
            }
        }
    }

}

.sidebar {
    position: fixed;
    height: 100%;
    width: 200px;
    left: 0;
    top: 0;
    padding: 80px 0 20px 0;
    color: white;
    background-color: $segundo;
    transition: 0.3s ease-in;
    overflow-y: auto;
    .empresa {
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column;
        transition: 0.3s ease-in-out;
        .logo {
            img {
                border: 2px solid $corporativo;
                border-color: white;
                width: 80px;
                height: 80px;
            }
        }
        .nombre {
            margin-top: 5px;
            font-family: $gotica;
            text-align: center;
        }
    }
    .graficas {
        cursor: pointer;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        background-color: $corporativo;
        padding: 10px;
        border-radius: 5px;
        margin-right: 20px;
        margin-left: 20px;
        svg {
            width: 25px;
            fill: white;
            margin-right: 10px;
        }
        p {
            font-family: $gotica;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    .opciones {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: space-between !important;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 10px;
            fill: white;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 14px;
            }
            svg {
                margin-right: 10px;
                width: 25px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .reportes {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: space-between !important;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 10px;
            fill: white;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 14px;
            }
            svg {
                margin-right: 10px;
                width: 15px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .incidencias {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: space-between !important;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 10px;
            fill: white;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 14px;
            }
            svg {
                margin-right: 10px;
                width: 18px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .sub-menu {
        margin-top: -20px;
        display: none;
        position: fixed;
        z-index: 100;
        background-color: $segundo;
        box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.5);
        font-family: $tipografiaCOntrol;
        left: 190px;
        transition: 0.5s;
        padding: 2px;
        li {
            /* font-size: 13px; */
            list-style: none;
            justify-content: space-between;
            display: flex;
            position: static;
            transition: 0.5s;
            padding-left: 2px;
            padding-right: 2px;
            cursor: pointer;
            a {
                text-decoration: none;
                color: white;
            }
            .nominas {
                padding: 5px;
                display: none;
                position: fixed;
                z-index: 100;
                background-color: $segundo;
                box-shadow: 0px 0px 10px 1px #000;
                font-family: $tipografiaCOntrol;
                left: 295px;
                transition: 0.5s;
                li {
                    padding-bottom: 2px;
                    display: list-item;
                    position: static;
                    transition: 0.5s;
                    a {
                        padding: 10px;
                    }
                }
            }
            a {
                padding: 5px;
            }
            svg {
                margin-left: 5px;
                width: 7px;
            }
        }
        li {
            &:hover,
            &:active {
                background-color: $corporativo;
                .nominas {
                    display: block !important;
                    transition: 0.5s;
                }
            }
        }
    }
}

@media screen and ( max-width: $tablets) {
    .sidebar {
        left: -200px;
        transition: 0.3s ease-in;
    }
}

//Sidebar reducer
.sidebar_reducer {
    position: fixed;
    height: 100%;
    width: 80px;
    left: 0;
    top: 0;
    padding: 80px 0 20px 0;
    color: white;
    background-color: $segundo;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    overflow-y: auto;
    .empresa {
        transition: 0.3s ease-in-out;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column;
        .logo {
            img {
                border: 2px solid $corporativo;
                border-color: white;
                width: 50px;
                height: 50px;
            }
        }
        .nombre {
            display: none;
        }
    }
    .graficas {
        cursor: pointer;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column;
        background-color: $corporativo;
        padding: 5px;
        border-radius: 5px;
        margin-right: 10px;
        margin-left: 10px;
        svg {
            width: 25px;
            fill: white;
            margin-right: 10px;
        }
        p {
            font-family: $gotica;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 10px;
        }
    }
    .opciones {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 10px;
            fill: white;
        }
        .arrow {
            display: none;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            flex-direction: column;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 9px;
            }
            svg {
                width: 25px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .reportes {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 12px;
            fill: white;
        }
        .arrow {
            display: none;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            flex-direction: column;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 9px;
            }
            svg {
                width: 15px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .incidencias {
        cursor: pointer;
        display: flex;
        align-items: center !important;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        padding: 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: 0.3s ease-in-out;
        svg {
            width: 10px;
            fill: white;
        }
        .descripcion {
            display: flex;
            align-items: center !important;
            justify-content: center;
            flex-direction: column;
            p {
                font-family: $gotica;
                margin-top: auto;
                margin-bottom: auto;
                font-size: 9px;
            }
            svg {
                width: 18px;
            }
        }
        &:hover,
        &:active {
            background-color: $corporativo;
            .sub-menu {
                display: block !important;
                transition: 0.5s;
            }
        }
    }
    .sub-menu {
        margin-top: -50px;
        display: none;
        position: fixed;
        z-index: 100;
        background-color: $segundo;
        box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.5);
        font-family: $tipografiaCOntrol;
        left: 70px;
        transition: 0.5s;
        padding: 2px;
        li {
            /* font-size: 13px; */
            list-style: none;
            justify-content: space-between;
            display: flex;
            position: static;
            transition: 0.5s;
            padding-left: 2px;
            padding-right: 2px;
            a {
                text-decoration: none;
                color: white;
            }
            .nominas {
                padding: 5px;
                display: none;
                position: fixed;
                z-index: 100;
                background-color: $segundo;
                box-shadow: 0px 0px 10px 1px #000;
                font-family: $tipografiaCOntrol;
                left: 180px;
                transition: 0.5s;
                li {
                    padding-bottom: 2px;
                    display: list-item;
                    position: static;
                    transition: 0.5s;
                    a {
                        padding: 10px;
                    }
                }
            }
            a {
                padding: 5px;
            }
            svg {
                margin-left: 5px;
                width: 7px;
            }
        }
        li {
            &:hover,
            &:active {
                background-color: $corporativo;
                .nominas {
                    display: block !important;
                    transition: 0.5s;
                }
            }
        }
    }
}