@import '../index.scss';
.navbar_top {
    position: fixed;
    z-index: 1000;
    width: 100%;
    background: whitesmoke;
    display: flex;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    height: 80px;
    .container_interior {
        padding: 3px 2%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .section__logo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: 120px;
                margin-right: 10px;
            }
            .menu_burguer {
                cursor: pointer;
                svg {
                    width: 25px;
                    color: $segundo;
                }
            }
            .menu_burguer_dark {
                cursor: pointer;
                svg {
                    width: 25px;
                    color: $corporativo;
                }
            }
        }
        .Contaier_navBar_principal {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 60px;
            svg {
                width: 40px;
                color: $corporativo;
            }
            img {
                width: 43px;
                height: 43px;
                box-shadow: 0 1rem 1rem rgba(0, 0, 0, .1);
                border: 2px solid $segundo;
                background-repeat: no-repeat;
                background-position: 50%;
                /* border-radius: 50%; */
                background-size: 100% auto;
            }
            .datauser {
                padding-right: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                font-family: $gotica;
                font-size: 12px;
                p {
                    margin-top: auto;
                    font-weight: bold;
                    color: $corporativo;
                }
                span {
                    margin-top: -20px;
                    color: $font-grey;
                }
            }
            .notifications {
                cursor: pointer;
                margin-left: 7px;
                border-radius: 100%;
                background-color: white;
                box-shadow: 0 1rem 1rem rgba(0, 0, 0, .1);
                svg {
                    padding: 10px;
                    padding-left: 12px;
                    padding-right: 12px;
                    width: 45px;
                    fill: $segundo;
                }
            }
            .options {
                cursor: pointer;
                margin-left: 7px;
                border-radius: 100%;
                background-color: white;
                box-shadow: 0 1rem 1rem rgba(0, 0, 0, .1);
                svg {
                    padding: 10px;
                    padding-left: 19px;
                    padding-right: 19px;
                    width: 44px;
                    fill: $corporativo;
                }
            }
            .menu {
                padding: 10px;
                margin-top: 155px;
                /* background-color: white; */
                color: white;
                font-family: $tipografiaCOntrol;
                position: fixed;
                ul {
                    background-color: $segundo;
                    margin: 0;
                    padding: 10px;
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        list-style: none;
                        padding: 3px;
                        svg {
                            width: 18px;
                            height: auto;
                            margin-right: 5px;
                            fill: white !important;
                            color: white !important;
                        }
                        span {
                            .form-switch {
                                .form-check-input {
                                    &:checked {
                                        background-color: $corporativo;
                                        border-color: $corporativo;
                                    }
                                }
                            }
                        }
                    }
                }
                /*  &::before,
                &:hover {
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid white;
                } */
                .triangulo {
                    border-color: $segundo;
                    margin-left: 141px;
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid $segundo;
                }
            }
            //Modo obscuro del menú
            .menu__dark {
                padding: 10px;
                margin-top: 155px;
                /* background-color: white; */
                color: white;
                font-family: $tipografiaCOntrol;
                position: fixed;
                ul {
                    background-color: #111111;
                    margin: 0;
                    padding: 10px;
                    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.5);
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        list-style: none;
                        padding: 3px;
                        svg {
                            width: 18px;
                            height: auto;
                            margin-right: 5px;
                            fill: white !important;
                            color: white !important;
                        }
                        span {
                            .form-switch {
                                .form-check-input {
                                    &:checked {
                                        background-color: $corporativo;
                                        border-color: $corporativo;
                                    }
                                }
                            }
                        }
                    }
                }
                .triangulo {
                    border-color: #111111;
                    margin-left: 141px;
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid #111111;
                }
            }
        }
    }
    @media screen and ( max-width: $tablets) {
        .container_interior {
            .Contaier_navBar_principal {
                .datauser {
                    display: none;
                }
            }
        }
    }
    /*   &:theme {
        background-color: #252a30;
    } */
}