@import '../index.scss';

.select-list-item.active {
    transition: 0.3 ease-in !important;
    background-color: #0d6efd !important;
    color: #fff !important;
}

.select-list-item:hover {
    transition: 0.3 ease-in !important;
    background-color: #0252c9 !important;
    color: #fff !important;
}

.select-list-item {
    transition: 0.3 ease-in !important;
    background-color: #fff !important;
    color: #000 !important;
}