@import "../index.scss";

.login-geo {

  background: url("../images/febrero.png") !important;
  background-position: bottom right !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-height: 100vh;
  padding-top: 6rem;
  padding-bottom: 6rem;

  .container-all {

    width: 100%;
    max-width: 1000px;
    margin: auto;
    box-shadow: 0px 5px 26px 8px #000;

    .ctn-form {
      width: 100%;
      padding: 40px;
      background: #f7f7f7;
      h1 {
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
        color: $segundo;
      }
      form {
        .row {
          .col-xl-12,
          .col-md-12,
          .col-sm-12 {
            padding-top: 5px;
            padding-bottom: 5px;
            input[type="text"],
            input[type="password"] {
              width: 100%;
              border-color: $segundo;
              outline-color: $segundo;
            }
          }
          .restaurar {
            margin-top: 10px;
            color: gray;
            text-decoration: none;
          }
          .btn {
            background-color: $corporativo;
            border-color: $corporativo;
            display: block;
            justify-content: center;
            width: 50%;
            color: white;
            font-size: 20px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
          }
          .text-footer {
            display: block;
            margin-top: 80px;
            text-align: center;
            font-size: 16px;
            a {
              color: $corporativo;
              text-decoration: none;
            }
          }
        }
      }
    }

  }

  .control-logo-geo {
    content: url("../images/controlhr_dark.png") !important;
    margin-bottom: 40px;
  }

}

.login {
  font-family: $tipografiaCOntrol;
  position: relative;
  min-height: 100vh;
  background: url("../images/febrero.png");
  background-position: right;
  background-size: cover;
  padding: 20px;
  .container-all {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-top: 100px;
    display: flex;
    box-shadow: 0px 5px 26px 8px #000;
    .ctn-form {
      width: 100%;
      padding: 40px;
      background: #f7f7f7;
      h1 {
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
        color: $segundo;
      }
      form {
        .row {
          .col-xl-12,
          .col-md-12,
          .col-sm-12 {
            padding-top: 5px;
            padding-bottom: 5px;
            input[type="text"],
            input[type="password"] {
              width: 100%;
              border-color: $segundo;
              outline-color: $segundo;
            }
          }
          .restaurar {
            margin-top: 10px;
            color: gray;
            text-decoration: none;
          }
          .btn {
            background-color: $corporativo;
            border-color: $corporativo;
            display: block;
            justify-content: center;
            width: 50%;
            color: white;
            font-size: 20px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
          }
          .text-footer {
            display: block;
            margin-top: 80px;
            text-align: center;
            font-size: 16px;
            a {
              color: $corporativo;
              text-decoration: none;
            }
          }
        }
      }
    }
    .ctn-text {
      width: 100%;
      background: url("../images/lateral_login.png");
      background-position: top;
      background-size: cover;
      .logo {
        width: 300px;
        display: block;
        margin: auto;
      }
      .title {
        position: relative;
        text-align: center;
        color: $corporativo;
        font-size: 50px;
        top: 40%;
      }
    }
  }
}

//RESPONSIVE
@media (max-width: $tablet) {
  .ctn-text {
    display: none;
  }
  .ctn-form {
    margin: auto;
    width: 100%;
  }
}
